<template>
  <div>
     
        <h3 v-if="pgLoading" class="bottom30 margin40 webkit-animation webkit-50per-50"></h3>
        <h3 v-if="!pgLoading" 
            class="bottom30 margin40"
            :class="($i18n.locale == 'ar') ? 'hacen_algeria rtl' : ''">
            {{ $t('app.categories') }}
        </h3>

        <ul class="pro-list padding-t-20"
            :class="($i18n.locale == 'ar') ? 'rtl' : ''">
            <li v-for="(cat, index) in categories" :key="index">
                <router-link v-if="$i18n.locale == 'en'"
                    :to="'/news?category='+cat.slug"
                    :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                    {{ ($i18n.locale == 'ar') ? cat.title_ar : cat.title_en }}
                </router-link>
                <router-link v-if="$i18n.locale == 'ar'"
                        :to="'/news?category='+cat.slug+'&lang=ar'"
                        :class="($i18n.locale == 'ar') ? 'hacen_algeria' : ''">
                    {{ ($i18n.locale == 'ar') ? cat.title_ar : cat.title_en }}
                </router-link>
            </li>
        </ul>

  </div>
</template>

<script>
export default {
    name: 'CategoryCard',
    props: [],
    data(){
        return {
            pgLoading: true,
            categories: [],
        }
    },
    created() {
        //
        this.fetchCategories();
    },
    methods: {
        //
        fetchCategories() {
            this.pgLoading = true;
            this.axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
            };
            const options = {
                url: window.baseURL+'/categories',
                method: 'GET',
                data: {},
                params: {}
            }
            this.axios(options)
            .then(res => {
                this.pgLoading = false;
                this.categories = res.data.items;
            })
            .catch(() => {})
            .finally(() => {});
        },


    }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
